@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

body {
  background-color: black;
  font-family: 'Nunito', sans-serif;
  color: white;
  margin: 0px;
  padding: 0px;
}

.App {
  margin: 0px;
  padding: 0px;
}

.main-content {
  min-height: 100%;
  margin-bottom: -40px;
}

.main-content:after {
  content: "";
  display: block;
  height: 100px;
}

.header {
  text-align: center;
  padding: 10px;
  min-height: 75px;
}

.header:after {
  content: '';
  display: table;
  clear: both;
}

.logo {
  float: left;
  padding: 0px;
  margin: 1px;
}

.harmonylogo {
  margin-right: 5px;

}

.walletbutton {
  background-color: rgb(57, 54, 54);
  color: #00AEE9;
  font-size: small;
  /*font-weight: bold;*/
  text-align: center;
  min-height: 45px;
  min-width: 135px;
  padding: 15px;
  margin: 5px;
  border-radius: 10px;
  border: none;
  transition-duration: 0.4s;
}

.walletbutton:hover {
  background-color: rgb(70, 65, 65);
  cursor: pointer;
}

.walletbutton:active {
  background-color: rgb(108, 99, 99);
  border-color: rgb(70, 65, 65);
}

.btn {
  background-color: rgb(174, 170, 170);
  color: black;
  font-size: medium;
  /*font-weight: bold;*/
  text-align: center;
  min-height: 40px;
  width: 125px;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  border: none;
  border-color: rgb(174, 170, 170);
  transition-duration: 0.4s;

}

.btn:hover {
  background-color: rgb(70, 65, 65);
  color: #00AEE9;
  cursor: pointer;
}

.headerleft {
  float: right;
}

@media screen and (max-width: 775px) {
  .header {
    float: none;
    margin: auto;
  }

  .headerleft {
    float: none;
    margin: auto;
  }
}


.depositbtn {
  /*background-color: rgb(180, 252, 180);*/
  min-width: 75px;
  margin: 5px;
  padding: 5px;
  border: solid, 5px;
  /*border-radius: 5px;*/
  font-weight: bold;
}

.withdrawbtn {
  /*background-color: rgb(255, 188, 188);*/
  min-width: 75px;
  margin: 5px;
  padding: 5px;
  border: solid, 5px;
  /*border-radius: 5px;*/
  font-weight: bold;
}

.valuepair {
  border: solid;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

/* Create three equal columns that float next to each other */
.column {
  float: left;
  min-width: 330px;
  width: 33.33%;
  font-family: 'Nunito', sans-serif;
  margin-left: auto;
  margin-right: auto;
}

.row {
  padding: 0px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  /*background-color: red;*/
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
@media screen and (max-width: 775px) {
  .column {
    width: 100%;
  }
}

.boxHeader {
  padding-left: 20px;
  font-family: 'Nunito', sans-serif;
  color: white;
}

.box {
  height: 425px;
  border: 0px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  text-align: center;
  /*border-image: linear-gradient(to bottom right, #00aee9, #69ffc9) 5; */
  font-family: 'Nunito', sans-serif;
  background-color: rgb(57, 54, 54);
  color: white;
}

.avlstake {
  color: green;
}

.notavlstake {
  color: red;
}

.main-body {
  min-height: 100%;
  height: 100%;
}

.deposit-box {
  max-width: 200px;
  height: 350px;
  border: 2px solid;
  padding: 5px;
  margin: 5px;
  border-radius: 20px;
  text-align: center;
  /*border-image: linear-gradient(to bottom right, #00aee9, #69ffc9) 5; */
  font-family: 'Nunito', sans-serif;
  background-color: white;
}

.footer {
  /*
  clear: both;
  position: relative; */
  /*position: fixed; 
  left: 0;
  bottom: 0;
  */
  background-color: rgb(57, 54, 54);
  text-align: center;
  padding: 10px;
  margin: 0%;
  height: 40px;
  width: 98.5%;
  color: #00AEE9;

}

@media screen and (max-width: 775px) {
  .row {
    max-width: 90%;
    margin: 10px;
  }
}

.currencyselect {
  color: azure;
  border: blue;
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* height: 400px; */
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  background: rgba(255, 255, 255, 0.6);
  /*padding: 10px;*/
}

.overlay-box {
  
  width: 350px;
  max-width: 85%;
  max-height: fit-content;
  margin: auto;
  
  /*border: 2px solid;
    border-radius: 20px;
    border-color: black;
    background-color: grey;*/
}

@media only screen and (max-width: 800px) {
  .overlay-box {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 350px;
    max-width: 90%;
    max-height: fit-content;
    
  }
}

.social-link:link, .social-link:visited {
  color: #00AEE9;
  cursor: pointer;
}

.social-link:hover, .social-link:active {
  color: #00AEE9;
  cursor: pointer;
}

.tx-column {
  float: left;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  margin-left: auto;
  margin-right: auto;
}

.tx-box {
  height: 400px;
  max-height: 400px;
  overflow: hidden;
  border: 0px solid;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  text-align: center;
  /*border-image: linear-gradient(to bottom right, #00aee9, #69ffc9) 5; */
  font-family: 'Nunito', sans-serif;
  background-color: rgb(57, 54, 54);
  color: white;
}

table, td, th {
 /* border: 1px solid black;*/
 border: none;
}

table {
  /*border-collapse: collapse;*/
  position: sticky;
  top: 0;
  width: 100%;
}

th {
  height: 30px;
}

@media screen and (max-width: 775px) {
  .col-4 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  } 
  .col-5 {
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;       
  } 
}

.table-scroll{
  /*width:100%; */
  display: block;
  empty-cells: show;
position: relative;
  /* Decoration */
  border-spacing: 0;
  border: none;
}

.table-scroll thead{
  /*background-color: #f1f1f1;  */
  position:relative;
  display: block;
  width:100%;
  /*overflow-y: scroll;*/
}

.table-scroll tbody{
  /* Position */
  display: block; 
  position:absolute;
  width:100%; 
  overflow-y:scroll;
  /* Decoration */
  border-top: 1px solid rgba(0,0,0,0.2);
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

.table-scroll td,.table-scroll th{
  flex-basis:100%;
  flex-grow:2;
  display: block;
  padding: 1rem;
  text-align:left;
}

/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}

.table-scroll tbody tr:nth-child(2n){
  background-color: rgba(130,130,170,0.1);
}

.body-half-screen{
  max-height: 300px;
}

.small-col{flex-basis:10%;}
/*
table.sticky-headers { width:100%; }
table.sticky-headers thead tr th { text-align:left; position:sticky; top:0; }
*/

.click-me {
  text-align: right;
  color: #00AEE9;
}

.click-me:hover {
  color: #FF6F00;
  cursor: pointer;
}
